import React from "react";
import CalendarComponent from "../components/CalendarComponent";
import styled from "styled-components";

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  color: #fff;
  padding: 120px 20px;

  /* 모바일 패딩 축소 */
  @media (max-width: 768px) {
    padding: 80px 10px;
  }
`;

const CalendarWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  border: 1px solid rgba(255, 215, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);

  /* 모바일에선 패딩 줄이기 */
  @media (max-width: 768px) {
    padding: 0.5rem;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      margin-left: 30px;
    }
  }
`;

const CalendarPage = () => {
  return (
    <Container>
      <CalendarWrapper>
        <h1>Calendar</h1>
        <CalendarComponent />
      </CalendarWrapper>
    </Container>
  );
};

export default CalendarPage;
