import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import { breakpoints } from "../styles/constants";
import bg1 from "../assets/1-1.jpg";
import bg2 from "../assets/2-2.jpg";
import bg3 from "../assets/3-3.jpg";
import bg4 from "../assets/4-4.jpg";

type ContentType = {
  [key: string]: {
    title: string;
    description: string;
  };
};

const About = () => {
  const { language } = useLanguage(); // ko / en / ja
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const backgroundImages = [bg1, bg2, bg3, bg4];

  // 1) ko/en/ja 추가
  const content: ContentType = {
    ko: {
      title: "About Us",
      description: `최고급 차량 애호가를 위해 특별히 준비된 맞춤형 한국 여행으로 가장 독점적이고 짜릿한 여정을 경험해 보세요.

최첨단 스포츠카와 고성능 럭셔리 차량을 운전하며 한국의 숨막히게 아름다운 풍경 속을 달려보세요. 활기찬 서울의 거리부터 그림처럼 아름다운 해안 고속도로까지, 스피드의 짜릿함과 함께 환상적인 경치를 만끽하실 수 있습니다. 세계적 수준의 숙박과 미식 요리, 그리고 한국의 대표 명소들에 대한 VIP 접근이 포함된 우리의 맞춤형 여행은 고속 주행의 짜릿함과 품격 있는 여행의 우아함을 완벽하게 결합한 잊을 수 없는 경험을 선사합니다.`,
    },
    en: {
      title: "About Us",
      description: `Embark on the most exclusive and exhilarating journey of your life with our bespoke Korean travel experiences, designed for connoisseurs of luxury vehicles.

Take the wheel of cutting-edge sports cars and high-performance luxury vehicles, as you cruise through Korea’s breathtaking landscapes. From the vibrant streets of Seoul to the scenic coastal highways, immerse yourself in the thrill of speed while relishing stunning vistas. Indulge in world-class accommodations, savor exquisite gourmet dining, and enjoy VIP access to the nation’s most iconic landmarks. Our curated trips effortlessly combine the rush of high-speed driving with the elegance and sophistication of luxury travel.`,
    },
    ja: {
      title: "私たちについて",
      description: `ラグジュアリーカー愛好家のために特別に用意されたカスタム韓国旅行で、最もエクスクルーシブで刺激的な旅を体験してください。

最先端のスポーツカーや高性能ラグジュアリー車を運転しながら、美しい韓国の風景を走り抜けましょう。活気あるソウルの街並みから絵のように美しい海岸沿いの高速道路まで、スピード感とともに絶景を堪能できます。世界レベルの宿泊施設やグルメ、そして韓国の代表的な名所へのVIPアクセスが含まれる当社のカスタム旅行は、高速走行のスリルとラグジュアリーな旅のエレガンスを完璧に融合し、忘れられない体験を提供します。`,
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prev) =>
        prev === backgroundImages.length - 1 ? 0 : prev + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [backgroundImages.length]);

  return (
    <Container>
      <ContentWrapper bgImage={backgroundImages[currentImageIndex]}>
        {/* 현재 language에 맞춰 컨텐츠 표시 */}
        <Title>{content[language].title}</Title>
        <Description>{content[language].description}</Description>
      </ContentWrapper>
    </Container>
  );
};

export default About;

/* ------ styled-components ------ */
const Container = styled.div`
  min-height: 100vh;
  background: #000;
  color: #fff;
  padding: 120px 0;
`;

const ContentWrapper = styled.div<{ bgImage: string }>`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1.5s ease;
    animation: fadeIn 1.5s ease-in-out;
    opacity: 0.5;
    z-index: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(1.1);
    }
    to {
      opacity: 0.5;
      transform: scale(1);
    }
  }
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  white-space: pre-line;
  color: #ccc;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  word-break: keep-all;
  word-wrap: break-word;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1rem;
    line-height: 1.6;
  }
`;
