import React, { useState, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";

// i18n 객체: ko/en/ja. (PDF 경로나 짧은 설명은 예시)
const i18n = {
  ko: {
    goToday: "오늘로 이동",
    monthTitle: (y: number, m: number) => `${y}년 ${m}월 일정`,
    consult: "더보기",
    noEvents: "이 달에 등록된 이벤트가 없습니다.",
    summaryLabel: "요약 :",
    includedLabel: "포함 사항 :",
    events: [
      {
        title: "유럽 포르쉐 투어 (프랑스)",
        start: new Date(2025, 2, 26),
        end: new Date(2025, 3, 3),
        color: "lightgreen",
        slug: "europe-tour",
        shortDesc:
          "요약 :\n - 파리-몽펠리에-칸-모나코\n - 6박 8일 여행\n - 포르쉐 5일간 드라이빙 (911, 718, Taycan, Cayenne)\n\n포함 사항 : \n - 4-5성급 호텔\n - 왕복 항공권\n - 포르쉐 렌탈 비용 및 연료\n - 미슐랭 스타 레스토랑 식사\n - 투어 중 식사",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "유럽 포르쉐 투어 (프랑스)",
        start: new Date(2025, 3, 6),
        end: new Date(2025, 3, 14),
        color: "lightblue",
        slug: "europe-tour-2",
        shortDesc:
          "요약 :\n - 파리-몽펠리에-칸-모나코\n - 6박 8일 여행\n - 포르쉐 5일간 드라이빙 (911, 718, Taycan, Cayenne)\n\n포함 사항 : \n - 4-5성급 호텔\n - 왕복 항공권\n - 포르쉐 렌탈 비용 및 연료\n - 미슐랭 스타 레스토랑 식사\n - 투어 중 식사",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "BMW M 투어 (독일, 스위스, 이탈리아)",
        start: new Date(2025, 3, 16),
        end: new Date(2025, 3, 22),
        color: "lightcoral",
        slug: "bmw-tour",
        shortDesc:
          "요약 :\n - 뮌헨-인스브루크-생모리츠-브레겐츠\n - 5박 7일 여행\n - BMW M 4일간 드라이빙 (M2, M3, M3 투어링, M4, M5, Z4 M40i)\n\n포함 사항 : \n - 4-5성급 호텔\n - 왕복 항공권\n - BMW M 렌탈 비용 및 연료\n - 미슐랭 스타 레스토랑 식사\n - 투어 중 식사",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "BMW M 투어 (독일, 스위스, 이탈리아)",
        start: new Date(2025, 3, 23),
        end: new Date(2025, 3, 29),
        color: "pink",
        slug: "bmw-tour-2",
        shortDesc:
          "요약 :\n - 뮌헨-인스브루크-생모리츠-브레겐츠\n - 5박 7일 여행\n - BMW M 4일간 드라이빙 (M2, M3, M3 투어링, M4, M5, Z4 M40i)\n\n포함 사항 : \n - 4-5성급 호텔\n - 왕복 항공권\n - BMW M 렌탈 비용 및 연료\n - 미슐랭 스타 레스토랑 식사\n - 투어 중 식사",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
    ],
  },
  en: {
    goToday: "Go Today",
    // monthTitle: (y: number, m: number) => `Events in ${m}/${y}`,
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthTitle: (y: number, m: number, t: any) =>
      `Events in ${t.monthNames[m - 1]}/${y}`,
    consult: "More",
    noEvents: "No events found for this month.",
    summaryLabel: "SUMMARY :",
    includedLabel: "WHAT IS INCLUDED :",
    events: [
      {
        title: "EUROPE Porsche Tour (France)",
        start: new Date(2025, 2, 26),
        end: new Date(2025, 3, 3),
        color: "lightgreen",
        slug: "europe-tour",
        shortDesc:
          "SUMMARY :\n - Paris-Montpellier-Cannes-Monaco\n - 6 Night 8 Days Trip\n - Porsche 5 Days Driving(911, 718, Taycan, Cayenne)\n\nWHAT IS INCLUDED : \n - 4-5 Starred Hotel\n - Round trp airplane ticket\n - Porsche rental fee & Fuel\n - Michelin starred restaurant dining\n - Meals during tour",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "EUROPE Porsche Tour (France)",
        start: new Date(2025, 3, 6),
        end: new Date(2025, 3, 14),
        color: "lightblue",
        slug: "europe-tour-2",
        shortDesc:
          "SUMMARY :\n - Paris-Montpellier-Cannes-Monaco\n - 6 Night 8 Days Trip\n - Porsche 5 Days Driving(911, 718, Taycan, Cayenne)\n\nWHAT IS INCLUDED : \n - 4-5 Starred Hotel\n - Round trp airplane ticket\n - Porsche rental fee & Fuel\n - Michelin starred restaurant dining\n - Meals during tour",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "BMW M Tour (Germany,Switzerland,Italy)",
        start: new Date(2025, 3, 16),
        end: new Date(2025, 3, 22),
        color: "lightcoral",
        slug: "bmw-tour",
        shortDesc:
          "SUMMARY :\n - Munich-Innsbruck-St.Moritz-Bregenz\n - 5 Night 7 Days Trip\n - BMW M 4 Days Driving (M2, M3, M3 Touring, M4, M5, Z4 M40i)\n\nWHAT IS INCLUDED : \n - 4-5 Starred Hotel\n - Round trp airplane ticket\n - BMW M rental fee & Fuel\n - Michelin starred restaurant dining\n - Meals during tour",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "BMW M Tour (Germany,Switzerland,Italy)",
        start: new Date(2025, 3, 23),
        end: new Date(2025, 3, 29),
        color: "pink",
        slug: "bmw-tour-2",
        shortDesc:
          "SUMMARY :\n - Munich-Innsbruck-St.Moritz-Bregenz\n - 5 Night 7 Days Trip\n - BMW M 4 Days Driving (M2, M3, M3 Touring, M4, M5, Z4 M40i)\n\nWHAT IS INCLUDED : \n - 4-5 Starred Hotel\n - Round trp airplane ticket\n - BMW M rental fee & Fuel\n - Michelin starred restaurant dining\n - Meals during tour",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
    ],
  },
  ja: {
    goToday: "今日に移動",
    monthTitle: (y: number, m: number) => `${y}年${m}月の予定`,
    consult: "もっと見る",
    noEvents: "この月に登録されたイベントはありません。",
    summaryLabel: "概要 :",
    includedLabel: "含まれるもの :",
    events: [
      {
        title: "ヨーロッパ ポルシェ ツアー (フランス)",
        start: new Date(2025, 2, 26),
        end: new Date(2025, 3, 3),
        color: "lightgreen",
        slug: "europe-tour",
        shortDesc:
          "概要 :\n - パリ-モンペリエ-カンヌ-モナコ 6泊8日 ポルシェ 5日間ドライビング（911, 718, Taycan, Cayenne）\n\n含まれるもの : \n - 4-5つ星ホテル, 往復航空券, ポルシェレンタル料, 燃料, ミシュラン星付きレストランでの食事, ツアー中の食事",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "ヨーロッパ ポルシェ ツアー (フランス)",
        start: new Date(2025, 3, 6),
        end: new Date(2025, 3, 14),
        color: "lightblue",
        slug: "europe-tour-2",
        shortDesc:
          "概要 :\n - パリ-モンペリエ-カンヌ-モナコ 6泊8日 ポルシェ 5日間ドライビング（911, 718, Taycan, Cayenne）\n\n含まれるもの : \n - 4-5つ星ホテル, 往復航空券, ポルシェレンタル料, 燃料, ミシュラン星付きレストランでの食事, ツアー中の食事",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "BMW M ツアー (ドイツ、スイス、イタリア)",
        start: new Date(2025, 3, 16),
        end: new Date(2025, 3, 22),
        color: "lightcoral",
        slug: "bmw-tour",
        shortDesc:
          "概要 :\nミュンヘン-インスブルック-サンモリッツ-ブレゲンツ, 5泊7日 BMW M 4日間ドライビング (M2, M3, M3ツーリング, M4, M5, Z4 M40i)\n\n含まれるもの : 4-5つ星ホテル, 往復航空券, BMW Mレンタル料, 燃料, ミシュラン星付きレストランでの食事, ツアー中の食事",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
      {
        title: "BMW M ツアー (ドイツ、スイス、イタリア)",
        start: new Date(2025, 3, 23),
        end: new Date(2025, 3, 29),
        color: "pink",
        slug: "bmw-tour-2",
        shortDesc:
          "概要 :\nミュンヘン-インスブルック-サンモリッツ-ブレゲンツ, 5泊7日 BMW M 4日間ドライビング (M2, M3, M3ツーリング, M4, M5, Z4 M40i)\n\n含まれるもの : 4-5つ星ホテル, 往復航空券, BMW Mレンタル料, 燃料, ミシュラン星付きレストランでの食事, ツアー中の食事",
        pdfLink: "/assets/G-Wagon_Winter_Tour(Client).pdf",
      },
    ],
  },
};

// 날짜가 특정 범위 안에 있는지
function isWithinRange(day: Date, start: Date, end: Date) {
  return day >= start && day <= end;
}

// 두 날짜 범위가 겹치는지
function areRangesOverlapping(
  start1: Date,
  end1: Date,
  start2: Date,
  end2: Date
) {
  return start1 <= end2 && end1 >= start2;
}

const CalendarComponent: React.FC = () => {
  const { language } = useLanguage();
  const t = i18n[language];

  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());
  // 드롭다운 열려있는 이벤트(slug)
  const [openedEventSlug, setOpenedEventSlug] = useState<string | null>(null);
  // 오른쪽 이벤트 호버 중이면 -> 달력에서 테두리 표시
  const [hoveredEventSlug, setHoveredEventSlug] = useState<string | null>(null);
  // 달력에서 호버 중인 날짜
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  // 바깥 클릭 감지 위해 ref
  const containerRef = useRef<HTMLDivElement>(null);

  // 달/연 월 이동
  const handleActiveStartDateChange = ({ activeStartDate }: any) => {
    if (activeStartDate) {
      setActiveStartDate(activeStartDate);
    }
  };

  // "오늘로 이동"
  const handleGoToday = () => {
    setActiveStartDate(new Date());
  };

  // 달력 날짜 클릭
  const handleDateClick = (value: Date) => {
    // 해당 날짜에 해당하는 이벤트 찾기
    const event = t.events.find((evt) =>
      isWithinRange(value, evt.start, evt.end)
    );
    if (event) {
      // 이미 열려 있는 slug면 토글
      setOpenedEventSlug((prev) => (prev === event.slug ? null : event.slug));
    } else {
      // 이벤트 없거나 다시 누르면 닫기
      setOpenedEventSlug(null);
    }
  };

  // 달력 tileClassName
  const getTileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month") {
      const classes: string[] = [];

      // 해당 날짜에 해당하는 모든 이벤트 찾기
      const matchedEvents = t.events.filter((evt) =>
        isWithinRange(date, evt.start, evt.end)
      );

      if (matchedEvents.length > 0) {
        // 각 이벤트의 slug를 이용해 클래스를 만듬
        matchedEvents.forEach((evt) => {
          classes.push(`highlight-${evt.slug}`);
        });

        // has-event 클래스 추가
        classes.push("has-event");
      } else {
        // no-event 클래스 추가
        classes.push("no-event");
      }

      // 추가: Highlight dates based on hoveredEventSlug
      if (hoveredEventSlug) {
        const hoveredEvent = t.events.find(
          (evt) => evt.slug === hoveredEventSlug
        );
        if (
          hoveredEvent &&
          isWithinRange(date, hoveredEvent.start, hoveredEvent.end)
        ) {
          classes.push("highlight-hovered-event");
        }
      }

      // 추가: Highlight dates based on hoveredDate
      if (hoveredDate) {
        const eventsOnHoveredDate = t.events.filter((evt) =>
          isWithinRange(hoveredDate, evt.start, evt.end)
        );
        eventsOnHoveredDate.forEach((evt) => {
          if (isWithinRange(date, evt.start, evt.end)) {
            classes.push(`highlight-hovered-date-${evt.slug}`);
          }
        });
      }

      return classes.join(" ");
    }
    return null;
  };

  // 이번 달 범위
  const currentYear = activeStartDate.getFullYear();
  const currentMonth = activeStartDate.getMonth();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const firstDayOfNextMonth = new Date(currentYear, currentMonth + 1, 1);
  const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

  // 이번 달 이벤트
  const monthEvents = t.events.filter((evt) =>
    areRangesOverlapping(evt.start, evt.end, firstDayOfMonth, lastDayOfMonth)
  );

  // 오른쪽 이벤트 클릭 -> 드롭다운
  const handleEventClick = (slug: string) => {
    setOpenedEventSlug((prev) => (prev === slug ? null : slug));
  };

  // 바깥 클릭 시 닫기
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (!containerRef.current) return;
      if (!containerRef.current.contains(e.target as Node)) {
        setOpenedEventSlug(null);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Helper function to format shortDesc
  const formatShortDesc = (desc: string) => {
    // Split the string by newline characters
    const lines = desc.split("\n");

    // Process each line
    return lines.map((line, index) => {
      let label = "";
      let content = line;

      if (language === "ko") {
        if (line.startsWith("요약 :")) {
          label = t.summaryLabel;
          content = line.replace("요약 :", "").trim();
        } else if (line.startsWith("포함 사항 :")) {
          label = t.includedLabel;
          content = line.replace("포함 사항 :", "").trim();
        }
      } else if (language === "en") {
        if (line.startsWith("SUMMARY :")) {
          label = t.summaryLabel;
          content = line.replace("SUMMARY :", "").trim();
        } else if (line.startsWith("WHAT IS INCLUDED :")) {
          label = t.includedLabel;
          content = line.replace("WHAT IS INCLUDED :", "").trim();
        }
      } else if (language === "ja") {
        if (line.startsWith("概要 :")) {
          label = t.summaryLabel;
          content = line.replace("概要 :", "").trim();
        } else if (line.startsWith("含まれるもの :")) {
          label = t.includedLabel;
          content = line.replace("含まれるもの :", "").trim();
        }
      }

      if (label) {
        return (
          <React.Fragment key={index}>
            <strong>{label}</strong> {content}
            <br />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        );
      }
    });
  };

  return (
    <CalendarPageContainer ref={containerRef}>
      {/* 왼쪽 달력 */}
      <CalendarBox>
        <CustomCalendarWrapper>
          <Calendar
            locale={
              language === "ko"
                ? "ko-KR"
                : language === "ja"
                ? "ja-JP"
                : "en-US"
            }
            calendarType="gregory"
            selectRange={false}
            onActiveStartDateChange={handleActiveStartDateChange}
            activeStartDate={activeStartDate}
            tileClassName={getTileClassName}
            // 날짜 클릭
            onClickDay={handleDateClick}
            // tileContent를 사용하여 날짜 hover
            tileContent={({ date, view }) => {
              if (view === "month") {
                // 해당 날짜에 이벤트가 있는지 확인
                const hasEvent = t.events.some((evt) =>
                  isWithinRange(date, evt.start, evt.end)
                );

                return (
                  <TileHoverArea
                    hasEvent={hasEvent}
                    onMouseEnter={() => {
                      setHoveredDate(date);
                    }}
                    onMouseLeave={() => {
                      setHoveredDate(null);
                    }}
                  />
                );
              }
              return null;
            }}
          />
        </CustomCalendarWrapper>

        <TodayButton onClick={handleGoToday}>{t.goToday}</TodayButton>
      </CalendarBox>

      {/* 오른쪽 이벤트 목록 */}
      <EventsBox>
        <MonthTitle>
          {t.monthTitle(currentYear, currentMonth + 1, t)}
        </MonthTitle>
        {monthEvents.length > 0 ? (
          <MonthEventsContainer>
            {monthEvents.map((evt) => {
              const isOpened = openedEventSlug === evt.slug;

              // 현재 달력에서 호버 중인 날짜가 이 이벤트의 범위 내인지
              const dateHoveredThisEvent =
                hoveredDate && isWithinRange(hoveredDate, evt.start, evt.end);

              // 이벤트 리스트에서 이 이벤트가 hoveredEventSlug와 일치하는지
              const isHoveredEvent = hoveredEventSlug === evt.slug;

              return (
                <MonthEventItem
                  key={evt.slug} // index 대신 slug 사용
                  color={evt.color}
                  isOpened={isOpened}
                  onMouseEnter={() => setHoveredEventSlug(evt.slug)} // Hover handler
                  onMouseLeave={() => setHoveredEventSlug(null)} // Hover handler
                  onClick={() => handleEventClick(evt.slug)}
                  className={
                    dateHoveredThisEvent || isHoveredEvent
                      ? "hovered-event"
                      : ""
                  }
                >
                  <EventHeader>
                    <EventTitle>{evt.title}</EventTitle>
                    <EventDateRange>
                      {evt.start.toLocaleDateString()} ~{" "}
                      {evt.end.toLocaleDateString()}
                    </EventDateRange>
                  </EventHeader>

                  {/* 드롭다운 (열려 있으면 표시) */}
                  <DropdownInfo isOpened={isOpened}>
                    <ShortDescription>
                      {evt.shortDesc
                        ? formatShortDesc(evt.shortDesc)
                        : "짧은 안내글 2줄..."}
                    </ShortDescription>
                    <ConsultButton
                      onClick={(e) => {
                        e.stopPropagation(); // 부모 클릭 이벤트(토글) 방지
                        window.open(evt.pdfLink, "_blank");
                      }}
                    >
                      {t.consult}
                    </ConsultButton>
                  </DropdownInfo>
                </MonthEventItem>
              );
            })}
          </MonthEventsContainer>
        ) : (
          <NoEventNotice>{t.noEvents}</NoEventNotice>
        )}
      </EventsBox>
    </CalendarPageContainer>
  );
};

export default CalendarComponent;

/* ----------------------- Styled Components ----------------------- */

const CalendarPageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 1rem;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap-reverse;
    padding: 1rem;
  }
`;

const CalendarBox = styled.div`
  flex: 1 1 300px;
  min-width: 300px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  position: relative; /* Ensure relative positioning for absolute children */

  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 0.5rem;
    background: none;
  }
`;

const CustomCalendarWrapper = styled.div`
  .react-calendar {
    position: relative; /* Added to establish a positioning context */
    border: none;
    width: 100%;
    max-width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 0.5rem 1rem; /* 조정하여 날짜 숫자가 중앙에 위치하도록 함 */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.95rem;
      padding: 0.5rem;
    }

    .react-calendar__month-view__weekdays__weekday {
      color: #b55;
      font-weight: 600;
      text-align: center; /* 요일 텍스트 중앙 정렬 */
    }
    .react-calendar__tile {
      position: relative; /* To position the hover area */
      height: 60px; /* 모든 날짜 타일의 높이를 동일하게 설정 */
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0; /* 기본 패딩 제거 */
    }
    .react-calendar__tile--now {
      background: #ffe2e2;
      border-radius: 4px;
    }
    .react-calendar__tile--active {
      background: #ffffff;
      color: black;
      border-radius: 4px;
      &:hover {
        background: #f1943f;
      }
    }
    .react-calendar__tile:hover {
      background: #eee;
      border-radius: 4px;
    }

    /* 이벤트별 달력 칸(타일) */
    .react-calendar__tile.highlight-europe-tour {
      background-color: lightgreen !important;
      border-radius: 6px;
    }
    .react-calendar__tile.highlight-europe-tour-2 {
      background-color: lightblue !important;
      border-radius: 6px;
    }
    .react-calendar__tile.highlight-bmw-tour {
      background-color: lightcoral !important;
      border-radius: 6px;
    }
    .react-calendar__tile.highlight-bmw-tour-2 {
      background-color: pink !important;
      border-radius: 6px;
    }

    /* 여러 이벤트의 색상을 나누어 표시 */
    .react-calendar__tile.has-multiple-events::after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 4px;
      background: linear-gradient(
        to right,
        lightgreen 33%,
        lightskyblue 33%,
        lightskyblue 66%,
        lightyellow 66%
      );
      border-radius: 2px;
    }

    /* Highlight dates based on hovered event */
    .react-calendar__tile.highlight-hovered-event {
      outline: 2px solid #004225;
      outline-offset: -2px;
      transition: outline 0.2s;
    }

    /* Highlight dates based on hovered date */
    .react-calendar__tile.highlight-hovered-date-europe-tour {
      outline: 2px solid #004225;
      outline-offset: -2px;
    }
    .react-calendar__tile.highlight-hovered-date-europe-tour-2 {
      outline: 2px solid #004225;
      outline-offset: -2px;
    }
    .react-calendar__tile.highlight-hovered-date-bmw-tour {
      outline: 2px solid #004225;
      outline-offset: -2px;
    }
    .react-calendar__tile.highlight-hovered-date-bmw-tour-2 {
      outline: 2px solid #004225;
      outline-offset: -2px;
    }

    /* 날짜에 이벤트가 있는 경우 커서를 포인터로 변경 */
    .react-calendar__tile.has-event {
      cursor: pointer;
    }
    /* 이벤트가 없는 경우 커서를 기본으로 변경 */
    .react-calendar__tile.no-event {
      cursor: default;
    }

    /* Optional: Style for hovered date area */
    .tile-hover-area:hover {
      /* 호버 시 배경색 변경을 원하지 않으므로 배경색 제거 */
      /* background-color: rgba(255, 0, 0, 0.94); */
    }
  }
`;

/* 호버 영역을 캘린더 타일에 직접 적용 */
const TileHoverArea = styled.div<{ hasEvent: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

// "오늘로 이동" 버튼 스타일
const TodayButton = styled.button`
  display: block;
  margin: 1rem auto 0;
  padding: 0.6rem 1.2rem;
  border: none;
  background: #3b82f6;
  color: white;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background: #2563eb;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// 이벤트 목록 박스 스타일
const EventsBox = styled.div`
  flex: 1 1 300px;
  min-width: 300px;
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  position: relative;

  /* Scrollable when overflow */
  max-height: 470px; /* 필요에 따라 조정 */
  overflow-y: auto;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 1rem;
    background: none;
  }
`;

// 월 제목 스타일
const MonthTitle = styled.h2`
  margin: 0;
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    color: #fff;
  }
`;

// 이벤트 목록 컨테이너 스타일
const MonthEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// 개별 이벤트 아이템 스타일
const MonthEventItem = styled.div<{ color: string; isOpened: boolean }>`
  background-color: ${(props) => props.color || "#fff"};
  border-radius: 8px;
  cursor: pointer;
  transition: border 0.2s;
  color: #004225;

  /* Event header */
  display: flex;
  align-items: left;
  gap: 1rem;
  padding: 1rem;

  /* Border on hover */
  &:hover {
    border: 2px solid #004225; /* 주황색 테두리 */
  }

  /* Hovered-event class */
  &.hovered-event {
    border: 2px solid #004225; /* 주황색 테두리 */
  }

  /* Dropdown info */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

// 이벤트 헤더 스타일
const EventHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

// 이벤트 제목 스타일
const EventTitle = styled.span`
  font-weight: 600;
  font-size: 1rem;
  flex: 1;
`;

// 이벤트 날짜 범위 스타일
const EventDateRange = styled.span`
  font-size: 0.9rem;
  color: #555;
`;

// 상담 버튼 스타일
const ConsultButton = styled.button`
  padding: 0.5rem 1rem;
  background: #f47b20;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;

  &:hover {
    background: #f15b00;
  }
`;

// 드롭다운 컨테이너 스타일
const DropdownInfo = styled.div<{ isOpened: boolean }>`
  max-height: ${(props) => (props.isOpened ? "250px" : "0")};
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  padding: ${(props) => (props.isOpened ? "1rem" : "0 1rem")};
`;

// 짧은 설명 스타일
const ShortDescription = styled.p`
  margin: 0 0 0.5rem 0;
  font-size: 0.9rem;
  line-height: 1.4;
`;

// 이벤트가 없는 경우 표시되는 노티스 스타일
const NoEventNotice = styled.p`
  margin-top: 1rem;
  color: #999;
  font-size: 0.95rem;
`;
